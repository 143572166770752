import React from "react"
import styled from "styled-components"
import { Container } from "../Section"
import UsaStateMap from "../../images/usa-states-map.svg"

const MapWrap = styled.div`
  svg{
    path{
      transition: all 0.5s ease-in-out;
    }
    a{
      &:hover{
        path{
          fill:#062c44;
        }
      }
    }
  }
`

const Map = ({ stateName, mapImage, cities }) => {
  if (!stateName) return null;
  if (!mapImage) return null;

  return (
    <MapWrap>
      <Container maxWidth="1200px">
        <img src={UsaStateMap} alt="USA Map" />
      </Container>
    </MapWrap>
  )
}

export default Map
